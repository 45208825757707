export const SENTRY_URL = `https://a7a40ec4356f428eadda56112fab0069@sentry2.ubiwhere.com/178`;

export const primaryColor = "#4DA989";
export const primaryColorOpacity = "#C2CDCB";
export const lightPrimaryColor = "#156448";
export const darkPrimaryColor = "#2C423F";
export const darkPrimaryColorOpacity = "#4C5B61";
export const secondaryColor = "#F4AC45";
export const secondaryColorHover = "rgba(244, 172, 69, 0.5)";
export const darkSecondaryColor = "#FFD200";
export const textOnPrimary = "#ffffff";
export const textonSecondary = "#000000";
export const bodyBackground = "#f7f7f7";
export const inactiveColor = "#d3d3d3";
export const lightInactiveColor = "#e9e9e9";
export const darkInactiveColor = "#ababab";
export const successColor = "#97bd60";
export const lightSuccessColor = "rgba(151, 189, 96, 0.05)"
export const infoColor = "#2987CF";
export const errorColor = "#f44336";
export const lightColor = "rgba(222,112,102,0.05)";
export const darkErrorColor = "#ba000d";
export const warningColor = "#FFEF67";
export const alertColor = "#EF8A17";
export const lightAlertColor = "rgba(239, 138, 23, 0.05)"
export const borderColor = "#6E7587";
export const white = "#ffffff";
export const black = "#000";
export const iconDarkColor = "#2a2b2a";
export const activeRowColor = "rgba(105, 161, 255, 0.25)";

export const AUTH_TOKEN_TYPE = `Bearer`
export const BASE_API_URL = `https://mng-payt-chaves.ubiwhere.com/api/`;
export const MEDIA_API_URL = `https://mng-payt-chaves.ubiwhere.com`;
export const WS_BASE_URL = `wss://mng-payt-chaves.ubiwhere.com/ws/`;

export const WS_JOIN_GROUP = `payt`;
export const MAP_BOX_KEY = `pk.eyJ1IjoicGF5dCIsImEiOiJjbHFwY29qY3ozZnBrMnFycXYzdmFheTNyIn0.C0zMmPr1c5saR6TFeph5CQ`;
export const GOOGLE_API_KEY = `AIzaSyDF09ifRhAeqZvmExKoSBR-ZkkLnT9KjfY`;

export const MAPCENTER = [-7.47275, 41.73952];
export const ZOOMLEVEL = [6];
export const MAXZOOMLEVEL = 21;

export const MAPINITIALBOUNDS = [
    [-7.47755, 41.74102],
    [-7.46235, 41.73552],
];

export const theme = {
    primaryColor,
    lightPrimaryColor,
    darkPrimaryColor,
    secondaryColor,
    darkSecondaryColor,
    textOnPrimary,
    textonSecondary,
    bodyBackground,
    inactiveColor,
    darkInactiveColor,
    successColor,
    errorColor,
    darkErrorColor,
    warningColor,
    alertColor,
    infoColor,
    white,
    black,
    borderColor,
    lightInactiveColor,
    iconDarkColor,
    lightColor,
    activeRowColor,
    primaryColorOpacity,
    darkPrimaryColorOpacity,
    secondaryColorHover,
    lightSuccessColor,
    lightAlertColor
};

export const SIMPLE_FEATURES = true
export const MOBILE_DEEPLINK = "payt"

export const ADDITIONAL_FEATURES = [
    "dashboard",
    // "login_metrics",
    // "inefficient_pickups_metrics",
    "containers",
    "deposits",
    "collects",
    "route_schedules",
    "maintenances",
    "alerts",
    "errors",
    // "transactions",
    // "trader_switch",
    // "occurrences",
    "funding_footer",
];

export const FUNDING_FOOTER_LOGOS = [
    'chaves/chaves_county.png',
];

export const COMMON_FUNDING_FOOTER_LOGOS = [
    'european_union_fc.png',
    'portugal_logo.png',
    'poseur.png',
];

export const AVAILABLE_CONTAINERS = ["bio_waste"];

export const AVAILABLE_ROLES = {
    administration: "Administração",
    trader: "Comerciante",
    maintenance: "Manutenção",
};
